import * as React from 'react';
import { Container, Divider, Grid } from '../semantic';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { TermsAndConditions } from './screens/TermsAndConditions';
import footerLogoCFSC from './footer-logo-cfsc.png';
import footerLogoCFSC2x from './footer-logo-cfsc@2x.png';
import footerLogoCanada from './footer-logo-canada.png';
import footerLogoCanada2x from './footer-logo-canada@2x.png';
import './Footer.css';
import { DisclosurePolicy } from './screens';
import { Modal } from './Modal';
import { Language } from '../typings/Language';

interface IOwnProps {
  bilingual?: boolean;
  locale: Language;
  year: number;
}

interface IOwnState {
  disclosureModalIsOpen: boolean;
  frenchTermsOfServiceModalIsOpen: boolean;
  termsOfServiceModalIsOpen: boolean;
}

type Props = IOwnProps & RouteComponentProps<{}> & WithNamespaces;

class Component extends React.Component<Props, IOwnState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      disclosureModalIsOpen: false,
      frenchTermsOfServiceModalIsOpen: false,
      termsOfServiceModalIsOpen: false,
    };
  }

  public render() {
    const { props } = this;

    const footerSecondary = (
      <Grid.Column className="site-footer__secondary" width={6}>
        <a href="https://cfsc-opec.org/" target="_blank" rel="noreferrer">
          <img
            alt="Computers for Success Canada - Ordinateurs Pour l'Excellence Canada"
            src={footerLogoCFSC}
            srcSet={`${footerLogoCFSC}, ${footerLogoCFSC2x} 2x`}
            className="site-footer__logos"
            height="44"
          />
        </a>
        <a
          href={
            props.locale === 'en'
              ? 'http://www.ic.gc.ca/eic/site/111.nsf/eng/home'
              : 'http://www.ic.gc.ca/eic/site/111.nsf/fra/accueil'
          }
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="With Funding from Canada"
            src={footerLogoCanada}
            srcSet={`${footerLogoCanada}, ${footerLogoCanada2x} 2x`}
            className="site-footer__logos"
            height="44"
          />
        </a>
      </Grid.Column>
    );

    const modals = (
      <React.Fragment>
        <Modal
          isOpen={this.state.termsOfServiceModalIsOpen}
          onRequestClose={() =>
            this.setState({ termsOfServiceModalIsOpen: false })
          }
          closeButtonText={props.t('general.back')}
        >
          <TermsAndConditions locale={props.locale} />
        </Modal>
        <Modal
          isOpen={this.state.disclosureModalIsOpen}
          onRequestClose={() => this.setState({ disclosureModalIsOpen: false })}
          closeButtonText={props.t('general.back')}
        >
          <DisclosurePolicy locale={props.locale} />
        </Modal>
      </React.Fragment>
    );

    if (props.bilingual) {
      return (
        <footer className="site-footer">
          <Container>
            <Divider />
            <Grid columns={2} stackable={true}>
              <Grid.Column className="site-footer__primary" width={10}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ termsOfServiceModalIsOpen: true });
                  }}
                  to=""
                >
                  Terms and Conditions
                </Link>
                {' • '}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ frenchTermsOfServiceModalIsOpen: true });
                  }}
                  to=""
                >
                  Avis
                </Link>
              </Grid.Column>
              {footerSecondary}
            </Grid>
          </Container>
          <Modal
            isOpen={this.state.termsOfServiceModalIsOpen}
            onRequestClose={() =>
              this.setState({ termsOfServiceModalIsOpen: false })
            }
            closeButtonText="Back"
          >
            <TermsAndConditions locale="en" />
          </Modal>
          <Modal
            isOpen={this.state.frenchTermsOfServiceModalIsOpen}
            onRequestClose={() =>
              this.setState({ frenchTermsOfServiceModalIsOpen: false })
            }
            closeButtonText="Revenir en arrière"
          >
            <TermsAndConditions locale="fr" />
          </Modal>
        </footer>
      );
    }

    return (
      <footer className="site-footer">
        <Container>
          <Divider />
          <Grid columns={2} stackable={true}>
            <Grid.Column className="site-footer__primary" width={10}>
              <p>
                {props.t('general.notWorkingCode')}{' '}
                <a
                  href="https://ywcavan.org/connecting-families-tech-support"
                  target="_blank"
                  rel="noreferrer"
                >
                  YWCA
                </a>{' '}
                {props.t('general.at')}{' '}
                <a href="mailto:CFTS@ywcavan.org">CFTS@ywcavan.org</a>{' '}
                {props.t('general.or')}{' '}
                <strong>
                  <a href="tel:+1-833-206-0599">1 833 206 0599</a>
                </strong>
              </p>

              <p>
                {props.t('general.generalQuestions')}
                <a
                  href={
                    props.locale === 'en'
                      ? 'https://ised-isde.canada.ca/site/connecting-families/en'
                      : 'https://ised-isde.canada.ca/site/familles-branchees/fr'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.t('abbreviations.code.ISED')}
                </a>{' '}
                {props.t('general.at')}{' '}
                <strong>
                  <a href="tel:+1-800-328-6189">1 800 328 6189</a>
                </strong>{' '}
                ({props.t('general.voice')}) {props.t('general.or')}{' '}
                {props.locale === 'fr' && props.t('general.at')}{' '}
                <a href="tel:+1-866-694-8389">1 866 694 8389</a> (
                {props.t('abbreviations.code.TTY')})
              </p>
              <p>{props.t('general.visitLibrary')}</p>

              <div className="copyright-notice">
                <span className="copyright">
                  © {props.year} {props.t('general.computersForSuccessCanada')}
                </span>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ termsOfServiceModalIsOpen: true });
                  }}
                  to=""
                >
                  {props.t('general.termsOfService')}
                </Link>
                {' • '}
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ disclosureModalIsOpen: true });
                  }}
                  to=""
                >
                  {props.t('general.disclosurePolicy')}
                </Link>
              </div>
            </Grid.Column>
            {footerSecondary}
          </Grid>
        </Container>
        {modals}
      </footer>
    );
  }
}

export const Footer = withRouter(withNamespaces()(Component));
