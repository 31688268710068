import * as permissionText from './permissionText';

const en = {
  abbreviations: {
    code: {
      CCB: 'CCB',
      CFSC: 'CFSC',
      CRA: 'CRA',
      ISP: 'ISP',
      OPEC: 'OPEC',
      PIPEDA: 'PIPEDA',
      TTY: 'TTY',
      ISED: 'ISED',
      YWCA: 'YWCA',
    },
    name: {
      CCB: 'Child Care Benefit',
      CFSC: 'Computers For Success Canada',
      CRA: 'Canada Revenue Agency',
      ISP: 'Internet Service Provider',
      OPEC: 'Ordinateurs pour l’excellence Canada',
      PIPEDA: 'Personal Information Protection and Electronics Document Act',
      TTY: 'Text Telephone',
      ISED: 'Innovation, Science and Economic Development Canada',
    },
  },
  addressEntry: {
    address: 'Address',
    addressLineTwo: 'Apartment, suite, unit, building, floor, etc.',
    aria: {
      postalCodeInput: 'Enter Postal Code',
    },
    city: 'City',
    computerEmailPhoneHelp:
      'In order to complete your order, we need a valid mobile phone number to send you a confirmation message with your order number. You can provide an email address if you also want to receive the confirmation by email.',
    englishKeyboard: 'English keyboard',
    firstName: 'First name',
    frenchKeyboard: 'French keyboard',
    ifYouCanNot: 'If you can not find the address in the above search',
    ifYouHaveEmail:
      'If you have an email address or mobile phone number, we encourage you to fill it in here. We will send you the information you will need to receive low-cost Internet.',
    lastName: 'Last name',
    locale: 'Which keyboard is right for you?',
    needHelp: 'Need help?',
    poBoxNote: 'Computers can not be shipped to a PO Box',
    postalCode: 'Postal Code',
    province: 'Province',
    startTyping: 'Start typing and select the matching address.',
    useThisForm: 'use this form',
  },
  application: {
    title: 'Connecting Families',
  },
  aria: {
    messages: {
      addressConfirmation: 'Navigated to address confirmation page.',
      addressConfirmationSubmit: 'Submitted address confirmation page.',
      applicationError: 'Navigated to the application error page.',
      computer: 'Navigated to computer offer page.',
      computerAddressConfirmation:
        'Navigated to computer address confirmation page.',
      computerAddressConfirmationSubmit:
        'Submitted computer address confirmation page.',
      computerAddressEntry: 'Navigated to computer mailing address page.',
      computerAddressEntrySubmit: 'Submitted computer mailing address page.',
      contactInfo: 'Navigated to contact info page.',
      contactInfoSubmit: 'Submitted contact info page.',
      finish: 'Navigated to contact ISP page.',
      finishComputerAddressEntrySubmit:
        'Submitted computer mailing address form.',
      finishContactInfoSubmit: 'Submitted contact info form.',
      internetService: 'Navigated to internet service page.',
      languageSelection: 'Navigated to language selection page.',
      mailingAddressEntry: 'Navigated to mailing address page.',
      mailingAddressEntrySubmit: 'Submitted mailing address page.',
      noInventory: 'Navigated to no computer inventory page.',
      noIsps: 'Navigated to no ISPs page.',
      noLetter: 'Navigated to no letter page.',
      noPermission: 'Navigated to no permission page.',
      permission: 'Navigated to permission page.',
      pinEntry: 'Navigated to code entry page.',
      pinEntrySubmit: 'Submitted code entry page.',
      provider: 'Navigated to ISP question page.',
      providerSubmit: 'Submitted ISP question page.',
      serviceAddressEntry: 'Navigated to internet delivery address page.',
      serviceAddressEntrySubmit: 'Submitted internet delivery address page.',
      termsOfService: 'Navigated to terms of service page.',
      welcome: 'Navigated to welcome page.',
    },
  },
  buttons: {
    close: 'Close',
    continue: 'Continue',
    saveAndCompleteOrder: 'Save & complete order',
  },
  errors: {
    computerEmailInvalid:
      'We were unable to use the email address <2>{{email}}</2> to place your order. Please <5>verify your email address</5> and try again.',
    computerPhoneInvalid:
      'We were unable to use the phone number <2>{{phone}}</2> to place your order. Please <5>verify your phone number</5> and try again.',
    notifyUserEmailFail:
      'We were unable to email you this information. Please make sure to print or write down the ISP contact information.',
    notifyUserFail:
      'We were unable to text or email you this information. Please make sure to print or write down the ISP contact information.',
    notifyUserPhoneFail:
      'We were unable to text you this information. Please make sure to print or write down the ISP contact information.',
    serverErrorText:
      'We’re very sorry, but our site is having an issue looking up codes. This may be because your internet signal is weak. Please try again at a local library or somewhere else with a stronger internet connection.',
    title: 'Sorry',
    unexpectedError: 'An unexpected error has occurred.',
  },
  formValidation: {
    cityRequired: 'City is required.',
    confirmEmailRequired: 'Confirmation email is required.',
    emailFormat: 'Please provide a valid email address.',
    emailMatch:
      'Sorry, the email address and confirmation email address don’t match.',
    emailMatchSkip:
      'Sorry, the email address and confirmation email address don’t match. Please make them match or <2>proceed without providing your contact details</2>.',
    firstNameRequired: 'First name is required.',
    lastNameRequired: 'Last name is required.',
    line1Required: 'Address is required.',
    mustEnterCode: 'You must enter a code.',
    phoneFormat: 'Please provide a valid mobile phone number.',
    phoneCountry: 'Please provide a valid Canadian mobile phone number.',
    pinFormat:
      'The code must contain 8 letters and numbers and begin with the letter “A“ or “B“.',
    pinInvalid:
      'We’re sorry, the code you have entered is not currently eligible. For more information please contact Innovation, Science and Economic Development Canada by email at ised-isde@canada.ca or by phone toll free at 1 800 328 6189.',
    postOfficeBoxServiceExcluded:
      'Sorry. Internet service cannot be provided to PO boxes. Please provide another address.',
    postOfficeBoxShippingExcluded:
      'Sorry. We cannot ship to PO boxes. Please provide another address.',
    postalCodeInvalid: 'This postal code is not valid.',
    postalCodeMustMatchProvince:
      'This postal code does not match the selected province.',
    postalCodeRequired: 'Postal code is required.',
    provinceRequired: 'Province is required.',
    required: 'This field is required.',
  },
  general: {
    about: 'About',
    aboutConnectingFamilies: 'About Connecting Families',
    back: 'Back',
    chatNow: 'Chat now',
    computersForSuccessCanada: 'Computers for Success Canada',
    connectingFamilies: 'Connecting Families',
    disclaimer:
      'This is the staging site. Please use the <1>production</1> site for customers.',
    disclosurePolicy: 'Disclosure Policy',
    letterImage: 'A letter from the Government of Canada',
    notWorkingCode: 'Is your code not working? For support, please contact',
    generalQuestions: 'For general questions, please call ',
    optional: 'Optional',
    or: 'or',
    at: 'at',
    termsOfService: 'Terms of Service',
    visitLibrary:
      'You can also visit your local public library if you would like additional help.',
    voice: 'Voice',
  },
  links: {
    connectingFamilies: 'https://connecting-families.ca',
    connectingFamiliesFaq:
      'https://ised-isde.canada.ca/site/connecting-families/en#faq',
  },
  paths: {
    addressConfirmation: 'address-confirmation',
    applicationError: 'application-error',
    computer: 'computer',
    computerAddressConfirmation: 'computer-address-confirmation',
    computerAddressEntry: 'computer-address-entry',
    contactInfo: 'contact-info',
    finish: 'finish',
    internetService: 'internet-service',
    mailingAddressEntry: 'mailing-address-entry',
    noInventory: 'no-inventory',
    noIsps: 'no-isps',
    noLetter: 'no-letter',
    noPermission: 'no-permission',
    permission: 'permission',
    pinEntry: 'enter-code',
    provider: 'provider',
    serviceAddressEntry: 'service-address-entry',
    welcome: 'welcome',
  },
  providerChoices: {
    access: 'Access Communications',
    beanfield: 'Beanfield',
    bell: 'Bell',
    ccap: 'CCAP',
    cogeco: 'Cogeco',
    hay: 'Hay Communications',
    noneOfTheAbove: 'None of the above.',
    northwestel: 'Northwestel, Inc.',
    rogers: 'Rogers',
    sasktel: 'Sasktel',
    shaw: 'Shaw',
    tbaytel: 'Tbaytel',
    telus: 'Telus',
    unsure: `I'm not sure.`,
    videotron: 'Vidéotron',
    westman: 'Westman Media Cooperative',
    quadro: 'Quadro Communication Co-Operative Inc.',
    ruralnet: 'Rural Net',
  },
  provinces: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  screens: {
    addressConfirmation: {
      affirmative: 'Yes, use this address',
      negative: 'No, use a different address instead',
      prompt: 'Do you want low-cost Internet at the address on your letter?',
      title: 'Address Confirmation',
    },
    applicationError: {
      heading: 'There was a problem saving your information.',
      paragraph1:
        'We’re very sorry, but our site had an issue saving your information. The problem has been reported. We’re working to fix the issue. Please try again in 10 to 20 minutes. If you still see this message please wait and try again as it may take longer to fix.',
      title: 'Application Error',
      tryAgain: 'Try Again',
    },
    computer: {
      noOption: 'No, I do not',
      paragraph1:
        'Through the <1>Connecting Families</1> initiative, you are also eligible to receive a free second-hand desktop computer from the <4>Government of Canada</4>.',
      paragraph2:
        'The computer is yours to keep and will be shipped to you <2>free of charge</2>.',
      prompt: 'Will you need a computer?',
      title: 'Computer Offer',
      yesOption: 'Yes, I need a computer',
    },
    computerAddressConfirmation: {
      affirmative: 'Yes, order & continue',
      negative: 'No, let me fix my address',
      paragraph1:
        'Please verify that the address you have entered is correct and has all the information needed (unit or apartment number, city, postal code, etc.) to deliver the computer.',
      prompt: 'Help us confirm your address',
      title: 'Computer Address Confirmation',
    },
    computerAddressEntry: {
      paragraph1:
        'To ensure you receive a computer, please enter your mailing address with your first and last name.',
      prompt: 'Where do we send the computer?',
      title: 'Computer Mailing Address',
    },
    contactInfo: {
      confirmEmailLabel: 'Re-enter your email address to confirm it',
      emailLabel: 'Email address',
      emailPaste: 'Please double-check that the email addresses are the same.',
      phoneLabel: 'Mobile phone number (not landline)',
      prompt:
        'Thank you. Your contact details will allow us to send you the information you need.',
      skipThisStep: 'skip this step',
      spamWarning:
        'If you provide an email address, please make sure you check your junk mail or spam folder so you do not miss any important messages about the initiative. If you do not provide contact details, have a pen and paper ready to write down your information.',
      span1: 'If you would rather not provide contact details',
      title: 'Contact Info',
    },
    finish: {
      beSureToWriteDown:
        'Be sure to write down the phone numbers of all the service providers before you leave this site.',
      closer: {
        paragraph1: '<0>Thank You</0>',
        paragraph2:
          'Please leave this page open until you have either contacted an Internet Service Provider or you have saved the information on this page to use at a later time.',
      },
      changeAddress: 'change address?',
      chosenIspSpan1: 'And',
      chosenIspSpan2: 'give them the code',
      chosenIspSpan3: 'from your letter.',
      computerImage: 'an image of a computer',
      failSaveApplication:
        'There was an error saving your application information.',
      ifYouHaveEmail:
        'If you have an email address or a mobile phone number, we can send you a confirmation with all the information you will need to receive low-cost Internet.',
      infoHasBeenSent:
        'This information has also been sent to your email and mobile phone.',
      infoHasBeenSentEmail:
        'This information has also been sent to your email.',
      infoHasBeenSentPhone:
        'This information has also been sent to your mobile phone.',
      listItem1: 'Your code from your letter:',
      listItem2: 'The address for internet service:',
      multipleHighlightedIsps:
        'Almost done. To receive low-cost Internet, please contact one of these participating Internet service providers now.',
      noChosenIspSpan1: 'Give them the code',
      noChosenIspSpan2: 'from your letter.',
      numberFirstCard: {
        call: 'Call',
        visitWebsite: 'visit {{name}}{{suffix}} website',
      },
      optedInToFreeComputerParagraph1:
        'Thank you for opting into receiving a free refurbished desktop computer from the <1>Government of Canada</1>.',
      optedInToFreeComputerParagraph2:
        'It will be shipped to you as soon as possible. For more information please call <2>1 800 328 6189</2> (Voice) or <4>1 866 694 8389</4> (TTY).',
      or: 'or',
      orderOnline: 'Order online',
      paragraph1: 'You will need to provide:',
      paragraph2: 'Available Internet',
      paragraph3:
        'Your contract with an Internet Service Provider contains important elements, including critical information about data overage charges. For this and more information, please visit the <2>Government of Canada’s Connecting Families website</2>.',
      pleaseWriteDown: 'Please write down the phone numbers on this page.',
      send: 'Send',
      sendMeThisInfo: 'Send me this information',
      siteFirstCard: {
        call: 'call',
        visitWebsite: 'Visit {{name}}’s website',
      },
      singleHighlightedIsp:
        'Almost done. To receive low-cost Internet, please contact {{name}} now.',
      singleHighlightedIspNoProvider:
        'Almost done. At this time, there is only one participating ISP in your area. To get your Internet, please contact {{name}} now.',
      tenDollarInternet:
        'Maximum price of <1>$10 per month</1> plus tax for <4>10 Mbps</4>.',
      thankYou:
        'Thank you. Be sure to contact an Internet service provider soon.',
      tips:
        '<0>TIP:</0> You can take a picture of the letter and email it to yourself. You can store it in a safe place with other valuables.',
      title: 'Contact ISP',
      twentyDollarInternet:
        'Maximum price of <1>$20 per month</1> plus tax for a minimum download speed of <3>50 Mbps</3>.',
      voice: 'Voice',
      yesNeedComputer: 'Yes, I need a computer',
      youMayAlsoContact:
        'You may also contact a participating Internet service provider below for Internet with your code.',
      needComputer: {
        free: 'Do you need a free computer device?',
        lowCost: 'Do you need a low-cost computer device?',
        freeOrLowCost: 'Do you need a free or low-cost computer device?',
      },
      computerAvailable:
        'You are also eligible to receive a second-hand computer device through the Government of Canada initiative ‘Connected Families.’',
      visitComputerProvider: 'Please visit the following website',
    },
    internetService: {
      affirmative: 'Yes, I do',
      negative: 'No, I do not',
      paragraph1:
        'Low-cost Internet is available to <1>both new subscribers and those with Internet already installed</1>. You will need to <1>communicate this address</1> to the Internet Service Provider when you call.',
      prompt:
        'Do you have Internet service already installed at this address now?',
      title: 'Internet Service',
    },
    mailingAddressEntry: {
      invalid:
        'This address does not match the address on the letter from the Government of Canada.',
      paragraph1:
        'The next page will allow you to change your address if necessary.',
      prompt:
        'Thank you. Please enter the address on the letter to verify your code.',
      title: 'Mailing Address',
    },
    noInventory: {
      heading: 'We’re sorry.',
      paragraph:
        'Due to very high demand, computers are no longer available in your province.',
      title: 'No Inventory',
    },
    noIsps: {
      heading:
        'We apologize but unfortunately there are currently no participating ISPs in your area.',
      paragraph1:
        'To learn more about the Connecting Families Initiative please contact <2><0>1 800 328 6189</0></2> (voice), <5>1 866 694 8389</5> (TTY) or visit the <8>Government of Canada’s Connecting Families website</8>.',
      paragraph2:
        'Please keep your letter, as ISPs serving your area may join the Initiative at a later time.',
      title: 'No ISPs',
    },
    noLetter: {
      heading: 'We’re sorry.',
      paragraph1:
        'To participate in the Connecting Families initiative, you need to have received a letter from the Government of Canada.',
      paragraph2:
        'For more information, please visit the <2>Government of Canada’s Connecting Families website</2>.',
      title: 'No Letter',
    },
    noPermission: {
      heading: 'If you change your mind, you can always come back.',
      paragraph1:
        'To start over, just return to <2>connecting-families.ca</2>. <5>You will need the original letter</5> you received in the mail, so make sure to keep it in a safe place. If possible, we recommend taking a picture of the letter as a back up.',
      paragraph2:
        'Keep the letter at all times ⁠— even once you have concluded your contract with the ISP. Keep the letter at all times ⁠— even once you have concluded your contract with the ISP. If you lose the letter with your PIN they cannot be reissued.',
      title: 'No Permission',
    },
    permission: {
      affirmative: 'I agree',
      negative: 'I disagree',
      privacyLink: 'https://cfsc-opec.org/en/privacy-policy/',
      prompt: 'We need your permission.',
      text: permissionText.en,
      title: 'Permission',
    },
    pinEntry: {
      aria: {
        codeInput: 'Enter Code',
      },
      helpText:
        'The code must contain 8 letters and numbers and begin with the letter A or the letter B.',
      prompt: {
        header:
          'Please enter the code found on the top right corner of your letter.',
        paragraph1:
          'If your code is 9 characters, <2>only enter the first 8 characters</2>.',
        paragraph2: 'If your code is 8 characters, enter all of them.',
      },
      title: 'Code Entry',
    },
    provider: {
      prompts: {
        internet: 'Which company currently provides your Internet service?',
        telephone:
          'Which company currently provides your Telephone (landline) service?',
        television:
          'Which company currently provides your Television (TV) service?',
      },
      title: 'ISP Question',
    },
    serviceAddressEntry: {
      prompt:
        'Please provide the address where you would like to have low-cost Internet.',
      title: 'Internet Delivery Address',
      useThisAddress: 'Use this address',
    },
    termsAndConditions: {
      title: 'Terms and Conditions',
    },
    welcome: {
      affirmative: 'Yes, I have a letter with a code',
      heading:
        'Connecting eligible people with low-cost home Internet services.',
      negative: 'No, I do not have this letter',
      paragraph1:
        'The Government of Canada’s Connecting Families initiative, in partnership with Internet service providers (ISPs), helps eligible people get home low-cost Internet services.',
      paragraph2:
        'The next few questions will help you connect with a participating Internet service provider.',
      prompt:
        'Did you receive a letter in the mail about this initiative, from the Government of Canada?',
      title: 'Welcome',
    },
  },
};

export default en;
