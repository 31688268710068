import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { LogoBubble } from './LogoBubble';
import computerImage from './computer.svg';
import { Language } from '../typings/Language';

interface IISPCardProps {
  locale: Language;
  url: string;
  province: string;
}

function ComputerCardImpl(props: IISPCardProps & WithNamespaces) {
  const { locale, url, t: originalT, province } = props;
  const t = (key: string, options?: Record<string, string>) =>
    originalT(key, { ...options, lng: locale });

  return (
    <LogoBubble
      logo={
        <img
          src={computerImage}
          width="70"
          height="64"
          alt={t('screens.finish.computerImage')}
        />
      }
      type="offer"
    >
      <h4>{t(getNeedComputerText(province))}</h4>
      <p>{t('screens.finish.computerAvailable')}</p>
      <p>
        <a href={url} target="_blank" rel="noreferrer">
          {t('screens.finish.visitComputerProvider')}
        </a>
      </p>
    </LogoBubble>
  );
}

function getNeedComputerText(province: string) {
  switch (province) {
    case 'AB':
    case 'BC':
    case 'QC':
    case 'ON':
      return 'screens.finish.needComputer.lowCost';
    case 'SK':
    case 'NL':
      return 'screens.finish.needComputer.free';
    default:
      return 'screens.finish.needComputer.freeOrLowCost';
  }
}

export const ComputerCard = withNamespaces()(ComputerCardImpl);
