import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Container, Form, Message } from '../../semantic';
import get from 'lodash/get';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { push } from 'connected-react-router';

import { Button, BackButton } from '../common';
import { verifyAddress, IAddress, verifyAddressReset } from '../../actions/api';
import { IAppState, IRequestState } from '../../reducers';
import { postalCodeRegex } from '../../constants';
import { addAccessibilityMessage } from '../../actions/app';
import { AddressFormInputs } from '../AddressFormInputs';
import letterImage from './cfsc-letter-address.png';
import letterImage2x from './cfsc-letter-address@2x.png';
import { postalMatchesProvince } from '../../util';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { Language } from '../../typings/Language';

interface IStateProps {
  address?: IAddress;
  locale: Language;
  verifyAddressRequest: IRequestState;
  sufficientState: boolean;
}

interface IDispatchProps {
  verifyAddress: typeof verifyAddress;
  verifyAddressReset: typeof verifyAddressReset;
  addAccessibilityMessage: typeof addAccessibilityMessage;
  push: typeof push;
}

type Props = IStateProps & IDispatchProps & WithNamespaces;

type IFormValues = IAddress;

class Component extends React.Component<Props> {
  public componentDidMount() {
    if (!this.props.sufficientState) {
      return this.props.push('/');
    }

    this.props.addAccessibilityMessage(
      this.props.t('aria.messages.mailingAddressEntry')
    );
    return;
  }

  public render() {
    const { props } = this;

    return (
      <Container text={true}>
        <HeadTitle title={props.t('screens.mailingAddressEntry.title')} />
        <BackButton />
        <Formik<IFormValues>
          initialValues={{
            city: get(props, 'address.city', ''),
            line1: get(props, 'address.line1', ''),
            line2: get(props, 'address.line2', ''),
            postalCode: get(props, 'address.postalCode', ''),
            province: get(props, 'address.province', ''),
          }}
          onSubmit={(values) => {
            if (['NU'].includes(values.province)) {
              this.props.push('/no-isps');
            } else {
              props.addAccessibilityMessage(
                props.t('aria.messages.mailingAddressEntrySubmit')
              );
              props.verifyAddress(values);
            }
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.line1) {
              errors.line1 = props.t('formValidation.line1Required');
            }
            if (!values.city) {
              errors.city = props.t('formValidation.cityRequired');
            }
            if (!values.province) {
              errors.province = props.t('formValidation.provinceRequired');
            }
            if (!values.postalCode) {
              errors.postalCode = props.t('formValidation.postalCodeRequired');
            } else if (!postalCodeRegex.test(values.postalCode)) {
              errors.postalCode = props.t('formValidation.postalCodeInvalid');
            } else if (
              values.province &&
              !postalMatchesProvince(values.postalCode, values.province)
            ) {
              errors.postalCode = props.t(
                'formValidation.postalCodeMustMatchProvince'
              );
            }

            return errors;
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Form
                size="large"
                autoComplete="off"
                error={true}
                onSubmit={handleSubmit}
              >
                <Heading>
                  {props.t('screens.mailingAddressEntry.prompt')}
                </Heading>
                <p>{props.t('screens.mailingAddressEntry.paragraph1')}</p>
                <AddressFormInputs
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  noPoBox={false}
                  setFieldValue={setFieldValue}
                  allowTerritories={true}
                />

                <Message
                  error={true}
                  aria-live="polite"
                  content={
                    props.verifyAddressRequest.error
                      ? props.t('errors.serverErrorText')
                      : null
                  }
                  role="alert"
                />
                <Message error={true} aria-live="polite" role="alert">
                  {props.verifyAddressRequest.invalid
                    ? props.t('screens.mailingAddressEntry.invalid')
                    : null}
                </Message>
                <Button
                  loading={props.verifyAddressRequest.loading}
                  type="submit"
                >
                  {props.t('buttons.continue')}
                </Button>
              </Form>
            );
          }}
        </Formik>
        <div className="letter-image-container">
          <img
            src={letterImage}
            srcSet={`${letterImage}, ${letterImage2x} 2x`}
            width="375"
            height="350"
            alt={props.t('general.letterImage')}
          />
        </div>
      </Container>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    address: state.mailingAddress,
    locale: state.locale,
    sufficientState: hasSufficientState(state),
    verifyAddressRequest: state.verifyAddressRequest,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      push,
      verifyAddress,
      verifyAddressReset,
    },
    dispatch
  );
};

export const MailingAddressEntry = withNamespaces()(
  connect<IStateProps, IDispatchProps, {}, IAppState>(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
